import styles from "./sass/settings.scss";

const theme = {
  color: {
    primary: styles.colorAccentPrimary,
  },
  wallColumns: 3,
  wallVerbs: {
    singular: "adornment",
    plural: "adornments",
    wallName: "Whakamaua | Wear It!",
  },

  logos: {
    byod: "/static/assets/artwall-rings.svg",
    wall: "/static/assets/wall-logo.svg",
  },
  sponsors: [
    {
      src: "/static/assets/tepapa-logo.png",
      name: "Museum of New Zealand Te Papa Tongarewa",
    },
  ],
  sendLabel: "Send to Wear It!",
  introText: {
    mi: "Tirotirohia ngā taonga whakarākei o ngā kohinga a Te Papa ka tuarihia ai tō kōwhiringa ki a Whakamaua!",
    en: "Browse adornments from Te Papa’s collections and share your choice to Wear It!",
  },
  footerCopy:
    'Ka pai – nice work! You’ve browsed <span class="nowrap">{}</span> adornments from Te Papa collections – art, history, taonga Māori, and Pacific cultures. ',
};

export default theme;
